/**
 * Common functionality for selecting the DOM element
 */
export const select = selector => {
  return document.querySelectorAll(selector);
};

/**
 *
 * @param {node} element
 * @param {number} to
 * @param {number} duration
 */
export const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  let difference = to - element.scrollTop;
  let perTick = (difference / duration) * 5; // calculating top per tick
  setTimeout(function() {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 5); // recursion
  }, 10);
};

var ScrollManager = {};

/**
 * Functionality for SmoothScroll behaviour
 */
ScrollManager.smoothScroll = function(selector, offsetTop = 0) {
  let target = select(selector);
  if (target.length <= 0) {
    return;
  }
  target = target[0];
  scrollTo(document.body, target.offsetTop - offsetTop, 200);
  scrollTo(document.documentElement, target.offsetTop - offsetTop, 200);
};

export const focusErrorField = ElementName => {
  select(`[name="${ElementName}"]`)[0].focus();
  ScrollManager.smoothScroll(`[name="${ElementName}"]`, 100);
};

export default ScrollManager;
