import React from "react";
import PropTypes from "prop-types";
import "../assets/scss/TeamComponent.scss";
import { useTranslation } from "react-i18next";

const TeamComponent = ({ teamList }) => {
  const { t } = useTranslation();
  return (
    <div id="teams" className="TeamComponent container">
      <div className="d-header">{t("teams")}</div>
      <ul className="teamList">
        {teamList &&
          teamList.map(team => (
            <li key={team.teamName}>
              <a href={team.url} target="_blank" rel="noopener noreferrer">
                <img src={team.imgSrc} alt={team.teamName} />
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

TeamComponent.propTypes = {
  teamList: PropTypes.arrayOf(
    PropTypes.shape({
      teamName: PropTypes.string.isRequired,
      imgSrc: PropTypes.any.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

export default TeamComponent;
