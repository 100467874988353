import React from "react";
import FooterComponent from "../components/FooterComponent";
import { footerData } from "../config/footer";

class FooterContainer extends React.Component {
  state = {
    data: {
      logo: "",
      socialLinks: []
    }
  };

  componentDidMount = () => {
    this.setState({
      data: { logo: footerData.logo, socialLinks: footerData.socialLinks }
    });
  };

  render() {
    let { data } = this.state;
    return <FooterComponent data={data} />;
  }
}

export default FooterContainer;
