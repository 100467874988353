import React from "react";
import PropTypes from "prop-types";

import "../assets/scss/FeedbackComponent.scss";
import SocialLinksComponent from "./SocialLinksComponent";
import ModalComponent from "./ModalComponent";
import { useTranslation, Trans } from "react-i18next";

const FeedbackComponent = ({
  data,
  feedbackInfo,
  onInfoChange,
  handleCheckBox,
  onFeedbackSubmit,
  errors,
  openToast,
  toastMsg,
  isModalOpen,
  openModalHandler,
  closeModalHandler
}) => {
  const { t } = useTranslation("feedback");
  return (
    <div id="contact_us" className="FeedbackComponent container">
      {isModalOpen ? (
        <div onClick={closeModalHandler} className="modal-overlay"></div>
      ) : null}
      <div className="wrapper">
        {<div className={`toaster ${openToast ? "open" : ""}`}>{toastMsg}</div>}
        <ModalComponent
          className="modal"
          show={isModalOpen}
          close={closeModalHandler}
        >
          <span>Terms and Conditions</span>
          <p>
            Visitors may post reviews, comments, photos, and other content; and
            submit suggestions, ideas, comments, questions, or other information
            (“Feedback”), so long as the Feedback is not illegal, obscene,
            threatening, defamatory, invasive of privacy, infringing of
            intellectual property rights, or otherwise injurious to third
            parties or objectionable and does not consist of or contain software
            viruses, political campaigning, commercial solicitation, chain
            letters, mass mailings, or any form of "spam." You may not use a
            false e-mail address, impersonate any person or entity, or otherwise
            mislead as to the origin of a card or other content. We reserve the
            right (but not the obligation) to remove or edit such Feedback for
            these or any other reasons, but does not regularly review posted
            Feedback. If you do post or submit Feedback, and unless we indicate
            otherwise, you grant Mythical Inc. a nonexclusive, royalty-free,
            perpetual, irrevocable, and fully sub-licensable right to use,
            reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, and display such Feedback throughout the
            world in any media, for no compensation, acknowledgement, or payment
            to you for any purpose whatsoever. You grant Mythical Inc. and
            sub-licensees the right to use the name that you submit in
            connection with such Feedback, if they choose. You represent and
            warrant that you own or otherwise control all of the rights to the
            Feedback that you post; that the Feedback is accurate; that use of
            the Feedback you supply does not violate this policy and will not
            cause injury to any person or entity; and that you will indemnify
            Mythical Inc. for all claims resulting from Feedback you supply.
            Mythical Inc. has the right but not the obligation to monitor and
            edit or remove any activity or Feedback. Mythical Inc. takes no
            responsibility and assumes no liability for any Feedback posted by
            you or any third party.
          </p>
        </ModalComponent>
        <div className="d-header">{t("title")}</div>
        <div className="panes">
          <div className="pane2">
            <p>{t("para1")}</p>
            {/* Discussion block */}
            {data && data.socialLinks && (
              <div className="discussion-block">
                <p>{t("para2")}</p>
                <ul className="social-links">
                  {<SocialLinksComponent socialLinks={data.socialLinks} />}
                </ul>
              </div>
            )}
          </div>
          <div className="pane2">
            <form noValidate name="contactUsForm">
              <div className="input-wrapper">
                <input
                  type="text"
                  className={errors.name ? "error" : ""}
                  name="name"
                  value={feedbackInfo.name}
                  onChange={onInfoChange}
                  placeholder={t("full_name")}
                  id="fullName"
                  required
                />
                <span className="error-msg">{t(errors.name)}</span>
              </div>
              <div className="input-wrapper">
                <input
                  className={errors.email ? "error" : ""}
                  type="email"
                  name="email"
                  value={feedbackInfo.email}
                  onChange={onInfoChange}
                  id="email"
                  placeholder={t("email")}
                  required
                />
                <span className="error-msg">{t(errors.email)}</span>
              </div>
              <div className="input-wrapper">
                <textarea
                  className={errors.message ? "error" : ""}
                  value={feedbackInfo.message}
                  onChange={onInfoChange}
                  placeholder={t("send_us_your_feedback")}
                  name="message"
                  id="feedbackMsg"
                  required
                />
                <span className="error-msg">{t(errors.message)}</span>
              </div>
              <div className="input-wrapper ">
                <label className="vertical-center">
                  <input
                    type="checkbox"
                    name="terms"
                    id="termsAndCond"
                    onChange={handleCheckBox}
                    required
                    checked={feedbackInfo.terms}
                    value={feedbackInfo.terms}
                  />
                  <span>
                    <Trans>
                      I agree to the{" "}
                      <span
                        className="open-tc-modal"
                        onClick={openModalHandler}
                      >
                        terms and conditions
                      </span>
                    </Trans>
                  </span>
                </label>
                <div className="error-msg">{t(errors.terms)}</div>
              </div>
              <div className="btn-container">
                <button
                  type="submit"
                  className="btn"
                  onClick={e => onFeedbackSubmit(e)}
                >
                  {t("send")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

FeedbackComponent.propTypes = {
  feedbackInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    terms: PropTypes.bool.isRequired
  }),
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
    terms: PropTypes.string
  }),
  openToast: PropTypes.bool.isRequired,
  toastMsg: PropTypes.string.isRequired,
  onInfoChange: PropTypes.func.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  onFeedbackSubmit: PropTypes.func.isRequired
};

export default FeedbackComponent;
