import React from "react";
import CarouselComponent from "../components/CarouselComponent";
import Parser from "rss-parser";

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

class CarouselContainer extends React.Component {
  state = {
    carouselStories: []
  };

  constructor(props) {
    super(props);
    this.getCarouselStories = this.getCarouselStories.bind(this);
    this.setStateWithStories = this.setStateWithStories.bind(this);
  }

  componentDidMount = () => {
    this.getCarouselStories();
  };

  setStateWithStories(responseData) {
    this.setState({
      carouselStories: responseData
    });
  }

  // Medium does not expose read API, so parse RSS feed
  getCarouselStories() {
    let parser = new Parser();
    let storiesFeedArr = [];

    // helper function for formatting img src and article summary
    function composeformattedContent(itemStr, startIdx, endIdx) {
      let imageStr = itemStr.substring(
        itemStr.indexOf(startIdx),
        itemStr.indexOf(endIdx)
      );
      return imageStr;
    }

    //  TODO fix formatting of article summary
    (async () => {
      let feed = await parser.parseURL(CORS_PROXY + "medium.com/feed/dgoods");
      feed.items.forEach(item => {
        let itemStr = JSON.stringify(item);

        let itemImgUrl =
          "http://" + composeformattedContent(itemStr, "cdn", '\\" /');
        let articleSumm = composeformattedContent(itemStr, "<p>", "</p");
        storiesFeedArr.push([item, itemImgUrl, articleSumm]);
      });
      this.setStateWithStories(storiesFeedArr);
    })();
  }

  render() {
    return (
      <div>
        {this.state.carouselStories.length > 0 && (
          <CarouselComponent data={this.state} />
        )}
      </div>
    );
  }
}

export default CarouselContainer;
