import React from "react";
import { useTranslation, Trans } from "react-i18next";
import downloadBtn from "../../assets/images/dgoods-download-btn.png";
import "../../assets/scss/AppDownloadBtn.scss";
import ScrollManager from "../../utils/ScrollManager";

const AppDownloadBtn = () => {
  let { t } = useTranslation("philosophy");
  let onFeedbackClick = e => {
    e.preventDefault();
    ScrollManager.smoothScroll("#contact_us");
  };
  return (
    <React.Fragment>
      <a
        href={t("downloadLink")}
        target="_blank"
        rel="noopener noreferrer"
        className="AppDownloadBtn"
      >
        <img src={downloadBtn} alt="dGoods Download" />
      </a>
      <div className="sub-text">
        <Trans
          i18nKey="we_welcome_your_feedback"
          defaults="We welcome your <0>feedback</0>"
          components={[
            <a href="/" onClick={e => onFeedbackClick(e)}>
              feedback
            </a>
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default AppDownloadBtn;
