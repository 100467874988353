// Schema sample format
// /**
//  * Required Keys: validators, label
//  * supported validators: "required", "email", {exact : "xxx"}
//  * label - the label of the input
//  *
//  * Optional Keys: customMessage
//  * format: { [supported_validator_keys] : "<message>"}
//  */
// let schema = {
//   name: { validators: ["required"], label: "Full Name" },
//   email: { validators: ["required", "email"], label: "Email" },
//   message: { validators: ["required"], label: "Message" },
//   terms: {
//     validators: ["required", { exact: true }],
//     label: "Terms and Conditions",
//     customMessage: {
//       exact: "You need to accept the terms to send feedback"
//     }
//   }
// };

let validateValue = (validatorKey, value) => {
  switch (validatorKey) {
    case "required":
      return value === null || value === "" || value === undefined
        ? "required"
        : "";
    case "email":
      return !isValidEmail(value) ? "email" : "";
    default:
      if (validatorKey.constructor === Object) {
        if (validatorKey.exact) {
          return value === validatorKey.exact ? null : `exact`;
        }
      }
      return;
  }
};

let getErrorMsg = (error, schema) => {
  let name = schema.label;
  let customMessage = schema.customMessage && schema.customMessage[error];
  switch (error) {
    case "required":
      return customMessage || `${name} is required`;
    case "email":
      return customMessage || `Email is not valid`;
    case "exact":
      return customMessage || "Please select the value";
    default:
      return error;
  }
};

export const validate = ({ name, value }, schema) => {
  let error = "";
  let validateKeys = schema[name].validators;
  if (validateKeys) {
    validateKeys.some(validateKey => {
      error = validateValue(validateKey, value);
      if (error) {
        error = getErrorMsg(error, schema[name]);
        return true;
      }
      return false;
    });
    return error;
  }
};

export const validateAll = (data, schema) => {
  let keys = Object.keys(schema);
  let errors = {};
  keys.map(key => {
    let error = validate({ name: key, value: data[key] }, schema);
    if (error) {
      errors[key] = error;
    }
    return errors;
  });
  return isEmptyObject(errors) ? null : errors;
};

export const isEmptyObject = object => {
  return object.constructor === Object && Object.keys(object).length === 0;
};

export const isValidEmail = email => {
  let regEx = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  return regEx.test(email);
};

export const schema = {
  name: { validators: ["required"], label: "Full Name" },
  email: { validators: ["required", "email"], label: "Email" },
  message: { validators: ["required"], label: "Message" },
  terms: {
    validators: ["required", { exact: true }],
    label: "Terms and Conditions",
    customMessage: {
      exact: "You need to accept the terms to send feedback"
    }
  }
};
