import footerLogo from "../assets/images/footer-logo.png";

export const socialLinks = [
  {
    name: "twitter",
    url: "https://twitter.com/dGoods_official"
  },
  {
    name: "telegram",
    url: "https://t.me/dGoods_official"
  },
  {
    name: "medium",
    url: "https://medium.com/dgoods"
  }
];

export const footerData = {
  logo: footerLogo,
  socialLinks
};
