import React from "react";
import { withTranslation } from "react-i18next";

import HeaderComponent from "../components/HeaderComponent";
import { headerData } from "../config/header";
import ScrollManager from "../utils/ScrollManager";

class HeaderContainer extends React.Component {
  state = {
    data: {
      logo: "",
      tagline: "",
      menus: [],
      languages: []
    },
    open: false,
    languageListOpen: false,
    languageDefault: "",
    languageDefaultIcon: ""
  };

  componentDidMount = () => {
    this.initialize();
    document.addEventListener("scroll", this.handleStickyMenu);
  };

  handleStickyMenu = e => {
    let headerElement = document.getElementsByClassName("Header");
    if (headerElement && headerElement.length > 0) {
      headerElement = headerElement[0];
      let headerHeight = headerElement.clientHeight;
      if (
        document.documentElement.scrollTop > headerHeight ||
        document.body.scrollTop > headerHeight
      ) {
        headerElement.parentElement.classList.add("fixed");
      } else {
        headerElement.parentElement.classList.remove("fixed");
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleStickyMenu);
  };

  initialize = () => {
    headerData.menus = headerData.menus.map(menu => {
      return {
        key: menu
          .toString()
          .replace(/ /g, "_")
          .toLowerCase(),
        value: menu
      };
    });

    this.setState(
      {
        ...this.state,
        open: false,
        languageListOpen: false,
        data: headerData,
      },
      () => {
        this.detectLang();
      }
    );
  };

  detectLang = (languageDefault, languageDefaultIcon) => {
    const { i18n } = this.props;
    var query = new URLSearchParams(window.location.search);
    var lang = query.get("lang");
    let langObj = {};

    for (let lang of headerData.languages) {
      let languageLng = lang.lng;
      langObj[languageLng] = [lang.title, lang.icon];
    }

    if (lang === null) {
      this.changeLanguage("en");
      languageDefault = langObj['en'][0];
      languageDefaultIcon = langObj['en'][1];
      this.setDefaultLanguage(languageDefault, languageDefaultIcon);
    } else if (i18n && i18n.options.resources[lang]) {
      this.changeLanguage(lang);
      this.setDefaultLanguage(langObj[lang][0], langObj[lang][1]);
    } else {
      this.changeLanguage(this.props.i18n.language);
    }
  };

  changeLanguage = lng => {
    let url = lng === "en" ? "/" : `/?lang=${lng}`;
    this.defaultLang = lng;
    this.props.history.push(url);
    this.props.i18n.changeLanguage(lng);
    this.setState({
      ...this.state,
      defaultLang: lng
    });
  };

  setDefaultLanguage = (defaultLng, defaultLngIcon) => {
    this.setState({
      ...this.state,
      languageDefault: defaultLng,
      languageDefaultIcon: defaultLngIcon
    });
  };

  menuToggle = () => {
    this.setState({
      ...this.state,
      open: !this.state.open
    });
  };

  languageToggle = () => {
    this.setState(prevState => ({
      languageListOpen: !prevState.languageListOpen
    }))
  };

  onMenuClick = e => {
    e.preventDefault();
    let id = e.target.parentElement.getAttribute("data-target");
    if (id && document.getElementById(id)) {
      // Make that scroll fixed to calculate the correct element offset
      document.querySelector(".App").classList.add("fixed");
      setTimeout(() => {
        let offsetTop = 0;
        if (window.innerWidth <= 768) {
          offsetTop = document.querySelectorAll("header")[0].clientHeight;
        }
        ScrollManager.smoothScroll(`#${id}`, offsetTop);
      }, 100);
    }
    this.menuToggle();
  };

  handleLanguageClick = (e) => {
    e.preventDefault();
    let langTitle = document.getElementById("lang-title");
    langTitle.innerHTML = e.currentTarget.innerHTML;
    this.changeLanguage(e.currentTarget.dataset.language);
    this.setState(prevState => ({
      languageListOpen: !prevState.languageListOpen
    }))
  };

  render() {
    let { data, open, languageListOpen, languageDefault, languageDefaultIcon } = this.state;
    return (
      <HeaderComponent
        data={data}
        open={open}
        languageListOpen={languageListOpen}
        languageDefault={languageDefault}
        languageDefaultIcon={languageDefaultIcon}
        menuToggle={this.menuToggle}
        onMenuClick={this.onMenuClick}
        languageToggle={this.languageToggle}
        handleLanguageClick={this.handleLanguageClick}
      />
    );
  }
}

export default withTranslation()(HeaderContainer);
