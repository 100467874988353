import blockchain1 from "../assets/images/microsoft_azure_logo.png";
import blockchain2 from "../assets/images/eos_logo.png";

export const blockchainData = [
  {
    blockchainName: "Microsoft Azure",
    imgSrc: blockchain1,
    url: ""
  },
  {
    blockchainName: "Eos",
    imgSrc: blockchain2,
    url: ""
  }
];
