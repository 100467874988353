export const socialLinks = [
  {
    name: "twitter",
    url: "https://twitter.com/dGoods_official"
  },
  {
    name: "telegram",
    url: "https://t.me/dGoods_official"
  }
];

export const boardData = {
  socialLinks
};
