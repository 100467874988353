import React from "react";
import BlockchainComponent from "../components/BlockchainComponent";
import { blockchainData } from "../config/blockchain";

class BlockchainContainer extends React.Component {
  state = {
    blockchainList: []
  };
  componentDidMount() {
    this.setState({
      blockchainList: blockchainData
    });
  }
  render() {
    let { blockchainList } = this.state;
    return <BlockchainComponent blockchainList={blockchainList} />;
  }
}

export default BlockchainContainer;
