import React from "react";
import FeedbackComponent from "../components/FeedbackComponent";
import { feedbackData } from "../config/feedback";
import { schema, validate, validateAll } from "../utils/validator";
import { REST } from "../utils/RestFactory";
import { focusErrorField } from "../utils/ScrollManager";

class FeedbackContainer extends React.Component {
  state = {
    data: {
      content: "",
      socialLinks: []
    },
    feedbackInfo: {
      name: "",
      email: "",
      message: "",
      terms: false
    },
    errors: {
      name: "",
      email: "",
      message: "",
      terms: ""
    },
    openToast: false,
    toastMsg: "",
    isModalOpen: false
  };

  constructor(props) {
    super(props);
    this.onFeedbackSubmit = this.onFeedbackSubmit.bind(this);
  }

  handleChange = e => {
    let { name, value } = e.target;
    let errors = this.state.errors;
    let errMsg = validate({ name, value }, schema);

    if (errMsg) {
      errors[name] = errMsg;
    } else {
      delete errors[name];
    }

    let feedbackInfo = {
      ...this.state.feedbackInfo,
      [name]: value
    };

    this.setState({
      ...this.state,
      feedbackInfo,
      errors
    });
  };

  handleCheckBox = e => {
    let { name, checked } = e.target;
    let { feedbackInfo } = this.state;
    feedbackInfo[name] = checked;

    let errors = this.state.errors;
    let errMsg = validate({ name, value: feedbackInfo[name] }, schema);
    if (errMsg) {
      errors[name] = errMsg;
    } else {
      delete errors[name];
    }

    this.setState({
      ...this.state,
      feedbackInfo
    });
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      data: feedbackData
    });
  }

  openToast = (flag, toastMsg) => {
    this.setState({
      ...this.state,
      openToast: flag,
      toastMsg
    });
    setTimeout(() => {
      this.openToast(false);
    }, 3000);
  };

  openModalHandler = (e) => {
    e.stopPropagation();    
    e.preventDefault();
    let bodyElement = document.body;
    bodyElement.classList.add('no-scroll');
    this.setState({
        isModalOpen: true
    });
  }

  closeModalHandler = () => {
    let bodyElement = document.body;
    bodyElement.classList.remove('no-scroll');
    this.setState({
        isModalOpen: false
    });
  }

  resetForm = cb => {
    this.setState(
      {
        ...this.state,
        feedbackInfo: {
          name: "",
          email: "",
          message: "",
          terms: false
        }
      },
      cb
    );
  };

  onFeedbackSubmit(e) {
    e.preventDefault();
    let { feedbackInfo } = this.state;
    let errors = validateAll(feedbackInfo, schema);
    if (errors) {
      this.setState({
        ...this.state,
        errors
      });
      if (Object.keys(errors).length > 0)
        focusErrorField(Object.keys(errors)[0]);
      return;
    }
    // call submit API
    REST(
      "POST",
      "",
      {
        name: feedbackInfo.name,
        message: feedbackInfo.message,
        email_address: feedbackInfo.email
      },
      res => {
        if (res.code === 201) {
          this.resetForm(() => {
            this.openToast(true, res.message);
          });
        } else {
          this.openToast(true, res.message);
        }
      },
      err => {
        console.log(JSON.stringify(err));
      }
    );
  }

  render() {
    let { data, feedbackInfo, errors, openToast, toastMsg, isModalOpen } = this.state;
    return (
      <FeedbackComponent
        data={data}
        feedbackInfo={feedbackInfo}
        onInfoChange={this.handleChange}
        handleCheckBox={this.handleCheckBox}
        onFeedbackSubmit={this.onFeedbackSubmit}
        errors={errors}
        openToast={openToast}
        toastMsg={toastMsg}
        isModalOpen={isModalOpen}
        openModalHandler={this.openModalHandler}
        closeModalHandler={this.closeModalHandler}
      />
    );
  }
}

export default FeedbackContainer;
