import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import HeaderContainer from "./containers/HeaderContainer";
import BoardContainer from "./containers/BoardContainer";
import BlockchainContainer from "./containers/BlockchainContainer";
import TeamContainer from "./containers/TeamContainer";
import FeedbackContainer from "./containers/FeedbackContainer";
import CarouselContainer from "./containers/CarouselContainer";
import FooterContainer from "./containers/FooterContainer";
import "./assets/scss/index.scss";

const App = () => (
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <Switch>
        <Route component={HomePageComponent} />
      </Switch>
    </BrowserRouter>
  </I18nextProvider>
);

export const HomePageComponent = props => (
  <div className="App">
    <HeaderContainer {...props} />
    <BoardContainer />
    <BlockchainContainer />
    <FeedbackContainer />
    <TeamContainer />
    <CarouselContainer />
    <FooterContainer />
  </div>
);

export default App;
