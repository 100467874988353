import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "../assets/scss/BoardComponent.scss";
import AppDownloadBtn from "./common/AppDownloadBtn";
import SocialLinksComponent from "./SocialLinksComponent";

const BoardComponent = ({ data }) => {
  let { t } = useTranslation("philosophy");
  return (
    <div id="our_philosophy" className="BoardComponent">
      <div className="container">
        <div className="box">
          <div className="overlay">
            <div className="d-header">{t("title")}</div>
            <p>{t("paraOne")}</p>
            <p>{t("paraTwo")}</p>
            <div className="download-link-container">
              <div className="download-link">
                <AppDownloadBtn />
              </div>
            </div>
            {/* Discussion block */}
            {data && data.socialLinks && (
              <div className="discussion-block">
                <div className="d-header">{t("join_the_discussion")}</div>
                <ul className="social-links">
                  {<SocialLinksComponent socialLinks={data.socialLinks} />}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BoardComponent.propTypes = {
  data: PropTypes.shape({ socialLinks: PropTypes.array.isRequired })
};

export default BoardComponent;
