let baseUrl = `${process.env.REACT_APP_API_URL}`;

export const REST = (
  restType = "POST",
  url = ``,
  data = {},
  successCallBack,
  errCallBack
) => {
  // Default options are marked with *
  return fetch(baseUrl + url, {
    method: restType, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .then(res => {
      return res.json();
    })
    .then(
      result => {
        successCallBack(result);
      },
      error => {
        errCallBack(error);
      }
    );
};
