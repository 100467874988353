import React from "react";
import PropTypes from "prop-types";
import "../assets/scss/BlockchainComponent.scss";
import { useTranslation } from "react-i18next";

const BlockchainComponent = ({ blockchainList }) => {
  const { t } = useTranslation();
  return (
    <div id="blockchains" className="BlockchainComponent container">
      <div className="d-header">{t("blockchains")}</div>
      <ul className="blockchainList">
        {blockchainList &&
          blockchainList.map(blockchain => (
            <li
              key={blockchain.blockchainName}
              className={blockchain.blockchainName}
            >
              <a
                href={blockchain.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={blockchain.imgSrc} alt={blockchain.blockchainName} />
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

BlockchainComponent.propTypes = {
  blockchainList: PropTypes.arrayOf(
    PropTypes.shape({
      blockchainName: PropTypes.string.isRequired,
      imgSrc: PropTypes.any.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

export default BlockchainComponent;
