import React from "react";
import PropTypes from "prop-types";
import InfiniteCarousel from "react-leaf-carousel";

import "../assets/scss/CarouselComponent.scss";
import { useTranslation } from "react-i18next";

// helper function to format image source
const formatImgSrc = imgSrc => {
  let formattedImgSrc =
    "https://" +
    imgSrc
      .split("")
      .slice(7)
      .join("");

  return formattedImgSrc;
};

// helper function to format Carousel article summary
const formatArticleSummary = articleSummary => {
  let formattedCharacters = [];
  articleSummary = articleSummary.split("");

  let currentIdx = 0;
  articleSummary.forEach(() => {
    if (articleSummary[currentIdx] === "<") {
      while (articleSummary[currentIdx] !== ">") {
        currentIdx++;
      }
      currentIdx++;
    }
    formattedCharacters.push(articleSummary[currentIdx]);
    currentIdx++;
  });
  return formattedCharacters.join("");
};

const CarouselComponent = ({ data }) => {
  let { t } = useTranslation("carousel");
  let carouselContent = data.carouselStories;

  const carouselSlides = carouselContent.map((carouselInfo, i) => {
    let content = carouselInfo[0];
    let imageSrc = carouselInfo[1];
    let articleSummary = carouselInfo[2];

    return (
      <div key={"slide_" + i} className="carousel-slide">
        <a href={content.link} target="_blank" rel="noopener noreferrer">
          <img
            className="carousel-img"
            alt={content.title}
            src={formatImgSrc(imageSrc)}
          />
          <p className="carousel-title">{content.title}</p>
          <p className="carousel-summary">
            {formatArticleSummary(articleSummary)}
          </p>
        </a>
      </div>
    );
  });

  return (
    <div id="news" className="CarouselComponent container">
      {carouselContent.length !== 0 && (
        <div>
          <div className="d-header">{t("title")}</div>
          <InfiniteCarousel
            className="carousel-infinite"
            breakpoints={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
            dots={true}
            arrows={false}
            showSides={false}
            slidesToScroll={1}
            slidesToShow={3}
          >
            {carouselSlides}
          </InfiniteCarousel>
        </div>
      )}
    </div>
  );
};

CarouselComponent.propTypes = {
  data: PropTypes.shape({
    carouselStories: PropTypes.array
  })
};

export default CarouselComponent;
