import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SocialLinksComponent = ({ socialLinks }) => {
  let { t } = useTranslation("socialLinks");
  return (
    <ul className="social-links">
      {socialLinks &&
        socialLinks.map(socialLink => (
          <li key={socialLink.name}>
            <a
              href={t(socialLink.name)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {socialLink.name === "twitter" && (
                <i className="fab fa-twitter" />
              )}
              {socialLink.name === "telegram" && (
                <i className="fab fa-telegram-plane" />
              )}
              {socialLink.name === "medium" && (
                <i className="fab fa-medium" />
              )}
            </a>
          </li>
        ))}
    </ul>
  );
};

SocialLinksComponent.propTypes = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default SocialLinksComponent;
