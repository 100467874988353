import React from "react";
import BoardComponent from "../components/BoardComponent";
import { boardData } from "../config/board";

class BoardContainer extends React.Component {
  state = {
    data: {
      socialLinks: []
    }
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      data: { socialLinks: boardData.socialLinks }
    });
  }

  render() {
    let { data } = this.state;
    return <BoardComponent data={data} />;
  }
}

export default BoardContainer;
