import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "../assets/scss/HeaderComponent.scss";
import SocialLinksComponent from "./SocialLinksComponent";

const Header = ({
  open,
  data,
  languageListOpen,
  languageDefault,
  languageDefaultIcon,
  menuToggle,
  languageToggle,
  onMenuClick,
  handleLanguageClick
}) => {
  const { t } = useTranslation("header");
  return (
    <header className={`Header ${open ? "open" : ""}`}>
      {/* <div className="language-toggle">
        <div className="language-toggle-positioner">
          <div className="language-toggle-header" onClick={languageToggle}>
            <span className="language-toggle-title" id="lang-title">
            <img className="lang-title-img lang-title-img-toggle" src={languageDefaultIcon} alt="circle-flag" />
            <span className="lang-title-text">{languageDefault}</span>
            </span>
            <span>{languageListOpen
              ? <i className="fas fa-sort-up" />
              : <i className="fas fa-sort-down" />
            }</span>
          </div>
          {languageListOpen && 
          <ul className="language-list">
          {data.languages.map((item) => (
            <li className="language-list-item" 
                key={item.id} 
                data-language={item.lng} 
                onClick={handleLanguageClick} >
                <span><img className="lang-title-img" src={item.icon} alt="circle-flag" />
                <span className="lang-list-title">{item.title}</span></span>
            </li>
            ))}
          </ul>}
        </div>
      </div> */}
      <img src={data.logo} alt="dGoods Logo" />
      <p className="tagline"> {t("tagline")}</p>
      <div
        className={`${open ? "open" : ""} menu-toggler`}
        onClick={menuToggle}
      >
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
      <nav className={open ? "open" : ""}>
        <ul className="header-menu">
          {data.menus.map(menu => (
            <li data-target={menu.key} key={menu.key}>
              <a href="/" onClick={onMenuClick}>
                {t(menu.key)}
              </a>
            </li>
          ))}
        </ul>
        {open && data && data.socialLinks && (
          <div className="discussion-block-container">
            <div className="discussion-block">
              <div>{"Join the discussion"}</div>
              <ul className="social-links">
                {<SocialLinksComponent socialLinks={data.socialLinks} />}
              </ul>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  menuToggle: PropTypes.func.isRequired,
  languageListOpen: PropTypes.bool.isRequired,
  languageDefault: PropTypes.string.isRequired,
  languageDefaultIcon: PropTypes.string.isRequired,
  languageToggle: PropTypes.func.isRequired,
  handleLanguageClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    menus: PropTypes.array.isRequired,
    logo: PropTypes.any
  })
};

export default Header;
