import React from "react";
import PropTypes from "prop-types";

import "../assets/scss/ModalComponent.scss";

const ModalComponent = (props) => {
    return (
        <div className="modal-wrapper"
            style={{
                display: props.show ? 'block' : 'none',
                opacity: props.show ? '1' : '0'
            }}>
            <div className="modal-content-wrapper">
                <div className="modal-header">
                    {props.children[0]}
                    <span className="close-modal-btn" onClick={props.close}>×</span>
                </div>
                <div className="modal-body">
                    {props.children[1]}
                </div>
            </div>
        </div>
    )
}

ModalComponent.propTypes = {
    props: PropTypes.shape({ 
        show: PropTypes.bool.isRequired,
        children: PropTypes.array.isRequired
     })
};

export default ModalComponent;