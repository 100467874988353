import React from "react";
import TeamComponent from "../components/TeamComponent";
import { teamData } from "../config/team";
class TeamContainer extends React.Component {
  state = {
    teamList: []
  };
  componentDidMount() {
    this.setState({
      teamList: teamData
    });
  }
  render() {
    let { teamList } = this.state;
    return <TeamComponent teamList={teamList} />;
  }
}

export default TeamContainer;
