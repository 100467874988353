import partner2 from "../assets/images/partner2.png";
import partner4 from "../assets/images/partner4.png";
import partner5 from "../assets/images/partner5.png";
import partner6 from "../assets/images/partner6.png";
import partner7 from "../assets/images/partner7.png";
import partner10 from "../assets/images/partner10.png";
import partner11 from "../assets/images/partner11.png";
import partner13 from "../assets/images/partner13.png";
import partner14 from "../assets/images/partner14.png";

export const teamData = [
  {
    teamName: "Mythical Games",
    imgSrc: partner6,
    url: "https://mythical.games/"
  },
  {
    teamName: "EOSLynx",
    imgSrc: partner5,
    url: "https://eoslynx.com/"
  },
  {
    teamName: "tokenPocket",
    imgSrc: partner2,
    url: "https://www.tokenpocket.pro/"
  },
  {
    teamName: "Cypherglass",
    imgSrc: partner4,
    url: "https://www.cypherglass.com/"
  },
  {
    teamName: "Bloks.io",
    imgSrc: partner7,
    url: "https://bloks.io/"
  },
  {
    teamName: "Greymass",
    imgSrc: partner10,
    url: "https://greymass.com/"
  },
  {
    teamName: "MEET.ONE",
    imgSrc: partner11,
    url: "https://meet.one/"
  },
  {
    teamName: "Chintai",
    imgSrc: partner13,
    url: "https://chintai.io/"
  },
  {
    teamName: "sense.chat",
    imgSrc: partner14,
    url: "https://www.sense.chat/"
  }
];
