import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "../assets/scss/FooterComponent.scss";
import SocialLinksComponent from "./SocialLinksComponent";

const FooterComponent = ({ data }) => {
  const { t } = useTranslation("footer");
  return (
    <div id="footer" className="FooterComponent">
      <div className="container">
        <div className="panes">
          <div className="left-pane">
            <p>{t("copyright")}</p>
            {data && data.socialLinks && (
              <div className="discussion-block">
                <div className="d-header">{t("join_the_discussion")}</div>
                <ul className="social-links">
                  {<SocialLinksComponent socialLinks={data.socialLinks} />}
                </ul>
              </div>
            )}
          </div>
          <div className="right-pane">
            <img
              src={data.logo}
              alt="dGoods footer logo"
              className="footer-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FooterComponent.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.any.isRequired,
    socialLinks: PropTypes.array.isRequired
  })
};

export default FooterComponent;
