import logo from "../assets/images/site-logo.png";
import usaLogo from "../assets/images/usa-logo.jpg";
import chinaLogo from "../assets/images/china-logo.jpg";

import { socialLinks } from "./board";

export const headerData = {
  logo,
  menus: ["Our Philosophy", "Teams", "Contact Us", "News"],
  socialLinks,
  languages: [
    {
        id: 0,
        lng: 'zh',
        title: '中文',
        icon: chinaLogo,
        selected: false
    },
    {
      id: 1,
      lng: 'en',
      title: 'English',
      icon: usaLogo,
      selected: false
    }
  ]
};
